import { Component } from '@angular/core';
import { AccessRights } from 'src/app/models/user';
import { AppConfig } from '@app/shared/utils/config/app-config';
import { PipelinesData } from '@app/models/pipeline_data';

declare let require: any;

const { version: appVersion } = require('../../../../../../package.json');

@Component({
	selector: 'app-left-menu',
	templateUrl: './application-left-menu.component.html',
	styleUrls: ['./application-left-menu.component.scss']
})
export class ApplicationLeftMenuComponent {
	public appVersion;
	readonly AccessRights = AccessRights;
	collapsible = true;
	collapse = true;

	constructor(private appConfig: AppConfig) {
		this.appVersion = appVersion;
	}

	get pipelineData(): PipelinesData {
		return this.appConfig.pipelineData;
	}
}
