import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AppConfig } from '@app/shared/utils/config/app-config';
import { BillingMethod, EnergyType, PaymentMethod } from '@app/models/constants';
import {
	BillingSendingMethod,
	Bundle,
	ContractDurationItem,
	EligibilityItem,
	EligibilityRequest,
	Promotion
} from '@app/models/product-and-service';
import { Registration } from '@app/models/registration';

@Injectable({
	providedIn: 'root'
})
export class ProductCatalogService {
	public bundles?: Bundle[];
	public isBundlesLoading: boolean;
	public selectedBundle?: Bundle;
	public selectedBundle$: BehaviorSubject<Bundle> = new BehaviorSubject<Bundle>(null);

	public bundlesLoadedSuccessfully: boolean;

	constructor(
		private http: HttpClient,
		private languageService: TranslateService,
		private config: AppConfig,
		private toastr: ToastrService
	) {}

	setSelectedBundle(bundle: Bundle) {
		this.selectedBundle = bundle;
		this.selectedBundle$.next(bundle);
	}

	getSelectedBundle$(): Observable<Bundle> {
		return this.selectedBundle$;
	}

	public getBundleMainCategory(bundle: Bundle) {
		if (bundle && bundle.products && bundle.products.length > 0) {
			return bundle.products[0].category;
		}

		return '';
	}

	public getCommercialCodeOf(code: string) {
		if (code) {
			const bundle = this.getMatchingBundle(code);

			if (bundle && bundle.code) {
				return bundle.name;
			}
		}
		return '';
	}

	public getMatchingBundle(code: string): Bundle {
		let found = {} as Bundle;
		if (this.bundles) {
			this.bundles.forEach((value) => {
				if (value.code && value.code === code) {
					found = value;
				}
			});
		}

		return found;
	}

	public findMatchingBundle(commercialCode: string, energy: EnergyType): Bundle {
		let found = {} as Bundle;
		if (this.bundles) {
			this.bundles.forEach((value) => {
				if (value.name && value.name === commercialCode) {
					if (EnergyType.DUAL === energy) {
						if (value.products && value.products.length === 2) {
							found = value;
						}
					} else if (value.products.length === 1) {
						const p = value.products.find((value1) => {
							return value1.subCategory.toUpperCase() === energy.toString().toUpperCase();
						});
						if (p && p.code) {
							found = value;
						}
					}
				}
			});
		}

		return found;
	}

	loadBundles() {
		if (!this.bundles || !this.isBundlesLoading) {
			this.isBundlesLoading = true;
			this.http.get<Bundle[]>(`${this.config.instance.backendUrl}/ui/product-catalog/bundles`).subscribe(
				(value) => {
					this.bundles = value;
					this.isBundlesLoading = false;
					this.bundlesLoadedSuccessfully = true;
				},
				() => {
					this.toastr.error("Couldn't load bundles !! \n This may affect RegStar", 'Error');
					this.isBundlesLoading = false;
					this.bundlesLoadedSuccessfully = false;
				}
			);
		}
	}

	loadBundlesAsync(): Promise<boolean> {
		if (!this.bundles || !this.isBundlesLoading) {
			this.isBundlesLoading = true;
			return new Promise((resolve) => {
				this.http.get<Bundle[]>(`${this.config.instance.backendUrl}/ui/product-catalog/bundles`).subscribe(
					(value) => {
						this.bundles = value;
						this.isBundlesLoading = false;
						this.bundlesLoadedSuccessfully = true;
						resolve(true);
					},
					() => {
						this.toastr.error("Couldn't load bundles !! \n This may affect RegStar", 'Error');
						this.isBundlesLoading = false;
						this.bundlesLoadedSuccessfully = false;
						resolve(false);
					}
				);
			});
		}
	}

	getBundleByCode(code: string): Observable<Bundle> {
		return this.http.get<Bundle>(`${this.config.instance.backendUrl}/ui/product-catalog/bundles/${code}/detail`);
	}

	getBundles(): Observable<Bundle[]> {
		if (this.bundles) {
			return of(this.bundles);
		}

		return this.http.get<Bundle[]>(`${this.config.instance.backendUrl}/ui/product-catalog/bundles`);
	}

	getActivePromotions(segment: string) {
		return this.http.get<Promotion[]>(`${this.config.instance.backendUrl}/ui/promotions/${segment}`);
	}

	getActivePromotionsForProductNameAndSector(segment: string, bundleCode: string, sector: string) {
		const url = new URL(`${this.config.instance.backendUrl}/ui/promotions`);

		if (segment) {
			url.searchParams.append('segment', segment);
		}
		if (bundleCode) {
			url.searchParams.append('bundleCode', bundleCode);
		}

		if (sector) {
			url.searchParams.append('sector', sector);
		}

		return this.http.get<Promotion[]>(url.href);
	}

	getBundlesBy(category: string, subCategory: string, segment: string, salesContext: string): Observable<Bundle[]> {
		const url = new URL(`${this.config.instance.backendUrl}/ui/product-catalog/bundles/category/${category}`);
		if (subCategory) {
			url.href += '/' + subCategory;
		}
		if (segment) {
			url.searchParams.append('market', segment.toUpperCase());
		}
		if (salesContext) {
			url.searchParams.append('salesContext', salesContext.toUpperCase());
		}
		return this.http.get<Bundle[]>(url.href);
	}

	getEnergyBundlesBySegment(segment: string): Observable<Bundle[]> {
		return this.http.get<Bundle[]>(
			`${this.config.instance.backendUrl}/ui/product-catalog/bundles/energy?market=${segment.toUpperCase()}`
		);
	}

	servicesEligibility(selectedBundle: Bundle[], currentServiceSelection: Bundle[]): Observable<Bundle[]> {
		const request = {} as EligibilityRequest;
		request.products = [];
		if (selectedBundle) {
			selectedBundle.forEach((value) => {
				if (value.products && value.products.length > 0) {
					value.products.forEach((product) => {
						const item = {} as EligibilityItem;
						item.code = product.code;
						request.products.push(item);
					});
				}
			});
		}
		if (currentServiceSelection) {
			request.services = [];
			currentServiceSelection.forEach((value) => {
				const item = {} as EligibilityItem;
				item.code = value.products[0].code;
				request.services.push(item);
			});
		}
		return this.http.post<Bundle[]>(
			`${this.config.instance.backendUrl}/ui/product-catalog/services/eligibility`,
			request
		);
	}

	getBundleTranslationForCode(bundleCode: string) {
		if (this.bundles && this.bundles.length > 0) {
			const found = this.bundles.find((value) => value.code === bundleCode);
			if (found) {
				return this.getBundleTranslation(found);
			}
		}
		return bundleCode;
	}

	getBundlePrice(bundleCode: string) {
		if (this.bundles && this.bundles.length > 0) {
			const found = this.bundles.find((value) => value.code === bundleCode);
			if (found) {
				return found.price ? found.price : '-';
			}
		}
		return '-';
	}

	getBundleTranslation(item: Bundle) {
		if (!item.i18n) {
			return item.code;
		}
		if (this.languageService.currentLang === 'nl') {
			return item.i18n.nl;
		} else if (this.languageService.currentLang === 'fr') {
			return item.i18n.fr;
		} else {
			return item.i18n.en;
		}
	}

	getContractDurationByRegion(bundle: Bundle, region: string): ContractDurationItem {
		const contractDurations: ContractDurationItem[] = bundle.contractDuration;
		// regions from product catalog bundles are different from localities
		if (region === 'BRU') {
			region = 'BXL';
		}
		if (region === 'FLA') {
			region = 'VL';
		}
		let contractDurationItem: ContractDurationItem;
		if (contractDurations) {
			contractDurations.forEach((value) => {
				if (value.region === region) {
					contractDurationItem = value;
				}
			});
		} else {
			return { region: region, max: 3, min: 3 } as ContractDurationItem;
		}

		return contractDurationItem;
	}

	getTranslationFromBundleCode(bundleCode: string) {
		const bundle: Bundle = this.bundles.find((value) => value.code === bundleCode);
		if (bundle) {
			return this.getBundleTranslation(bundle);
		} else return null;
	}

	public setBillingValues(promotion: Promotion, registration: Registration) {
		if (promotion && promotion.applicationCriteria && registration) {
			registration.billingInfo.paymentMethod = promotion.applicationCriteria.paymentMethod;
			registration.billingInfo.billingMethodType = promotion.applicationCriteria.billingFrequency;
			registration.billingInfo.electronicInvoicing =
				promotion.applicationCriteria.billingSendingMethod === BillingSendingMethod.ALL ||
				promotion.applicationCriteria.billingSendingMethod === BillingSendingMethod.EMAIL;
			return;
		}
		if (!this.selectedBundle?.subscriptionRules) {
			return;
		}
		if (
			this.selectedBundle?.subscriptionRules?.billingSendingMethods?.find(
				(value) => value === BillingSendingMethod.ALL || value === BillingSendingMethod.EMAIL
			)
		) {
			registration.billingInfo.electronicInvoicing = true;
		} else if (
			this.selectedBundle?.subscriptionRules?.billingSendingMethods?.find(
				(value) => value === BillingSendingMethod.PAPER || value === BillingSendingMethod.POST
			)
		) {
			registration.billingInfo.electronicInvoicing = false;
		} else {
			registration.billingInfo.electronicInvoicing = false;
		}

		if (this.selectedBundle?.subscriptionRules?.paymentMethods?.find((value) => value === PaymentMethod.ALL)) {
			registration.billingInfo.paymentMethod = PaymentMethod.BANK_DOMICILIATION;
		} else if (
			this.selectedBundle?.subscriptionRules?.paymentMethods?.find((value) => value === PaymentMethod.BANK_DOMICILIATION)
		) {
			registration.billingInfo.paymentMethod = PaymentMethod.BANK_DOMICILIATION;
		} else if (
			this.selectedBundle?.subscriptionRules?.paymentMethods?.find((value) => value === PaymentMethod.BANK_TRANSFER)
		) {
			registration.billingInfo.paymentMethod = PaymentMethod.BANK_TRANSFER;
		} else if (
			this.selectedBundle?.subscriptionRules?.paymentMethods?.find(
				(value) => value === PaymentMethod.BANK_DOMICILIATION_AND_BANK_TRANSFER
			)
		) {
			registration.billingInfo.paymentMethod = PaymentMethod.BANK_DOMICILIATION_AND_BANK_TRANSFER;
		}

		const billingFrequencies = this.selectedBundle?.subscriptionRules?.billingFrequencies;
		if (billingFrequencies && billingFrequencies.length === 1) {
			registration.billingInfo.billingMethodType = BillingMethod[billingFrequencies[0]];
		}
	}
}
