<clr-vertical-nav
	class="nav-trigger--bottom h-100"
	[clrVerticalNavCollapsible]="collapsible"
	[(clrVerticalNavCollapsed)]="collapse">
	<a clrVerticalNavLink routerLink="dashboard" routerLinkActive="active">
		<cds-icon clrVerticalNavIcon shape="pie-chart"></cds-icon>
		{{ 'menu.left.dashboard.main_section' | translate }}
	</a>

	<div class="nav-divider"></div>

	<a clrVerticalNavLink routerLink="/acquisitions" routerLinkActive="active">
		<cds-icon clrVerticalNavIcon shape="employee"></cds-icon>
		Acquisition
	</a>

	<div class="nav-divider"></div>

	<a clrVerticalNavLink routerLink="/renewal/board" routerLinkActive="active">
		<cds-icon clrVerticalNavIcon shape="recycle"></cds-icon>
		Renewal
	</a>

	<div class="nav-divider"></div>

	<clr-vertical-nav-group routerLinkActive="active" *hasAccess="AccessRights.VIEW_REGISTRATION_OVERVIEW">
		<cds-icon shape="note" clrVerticalNavIcon></cds-icon>
		{{ 'menu.left.registration.main_section' | translate }}
		<clr-vertical-nav-group-children>
			<a clrVerticalNavLink *hasAccess="AccessRights.VIEW_REGISTRATION_OVERVIEW" routerLink="/registrations">
				{{ 'menu.left.registration.section_consultation' | translate }}
			</a>
			<a clrVerticalNavLink *hasAccess="AccessRights.VIEW_WORKLIST_OVERVIEW" routerLink="/registrations/working-list">
				{{ 'menu.left.registration.section_working-list' | translate }}
			</a>
			<a *hasAccess="AccessRights.VIEW_BUYING_GROUP" clrVerticalNavLink routerLink="/registrations/buying-group">
				{{ 'menu.left.registration.section_buying-group' | translate }}
			</a>
		</clr-vertical-nav-group-children>
	</clr-vertical-nav-group>
	<div class="nav-divider"></div>

	<clr-vertical-nav-group routerLinkActive="active" *hasAccess="AccessRights.VIEW_MOVE_OVERVIEW">
		<cds-icon shape="two-way-arrows" clrVerticalNavIcon></cds-icon>
		{{ 'menu.left.move.main_section' | translate }}
		<clr-vertical-nav-group-children>
			<a clrVerticalNavLink *hasAccess="AccessRights.VIEW_MOVE_OVERVIEW" routerLink="/move">
				{{ 'menu.left.registration.section_consultation' | translate }}
			</a>
			<a clrVerticalNavLink *hasAccess="AccessRights.VIEW_WORKLIST_OVERVIEW" routerLink="/move/working-list">
				{{ 'menu.left.registration.section_working-list' | translate }}
			</a>
		</clr-vertical-nav-group-children>
	</clr-vertical-nav-group>
	<div class="nav-divider"></div>

	<a clrVerticalNavLink routerLink="reporting" *hasAccess="AccessRights.REPORTING" routerLinkActive="active">
		<cds-icon clrVerticalNavIcon shape="container-volume"></cds-icon>
		{{ 'menu.left.reporting.main_section' | translate }}
	</a>

	<clr-vertical-nav-group routerLinkActive="active">
		<cds-icon shape="unlock" clrVerticalNavIcon></cds-icon>
		{{ 'menu.left.admin.main_section' | translate }}

		<clr-vertical-nav-group-children>
			<a clrVerticalNavLink routerLink="monitoring" routerLinkActive="active">Camunda</a>
		</clr-vertical-nav-group-children>

		<clr-vertical-nav-group-children>
			<a clrVerticalNavLink routerLink="caches" routerLinkActive="active">Caches</a>
		</clr-vertical-nav-group-children>

		<clr-vertical-nav-group-children *hasAccess="AccessRights.VIEW_DOCUMENTS_TEMPLATE_MANAGER">
			<a clrVerticalNavLink routerLink="documents-template-manager" routerLinkActive="active">Documents</a>
		</clr-vertical-nav-group-children>
	</clr-vertical-nav-group>

	<div *ngIf="!collapse">
		<div *hasAccess="AccessRights.VIEW_PIPELINE_DATA" class="pipeline_data">
			<p>
				F :
				<a
					[href]="
						'https://gitlab.lampiris.be/activation/be-regstar-frontend/-/pipelines/' + pipelineData?.frontend?.pipeline_id
					">
					{{ pipelineData?.frontend?.pipeline_id }}
				</a>
				<span>- {{ pipelineData?.frontend?.pipeline_timestamp | date: 'dd/MM HH:mm' }}</span>
			</p>
			<p>
				B :
				<a
					[href]="
						'https://gitlab.lampiris.be/activation/be-regstar-backend/-/pipelines/' + pipelineData?.backend?.pipeline_id
					">
					{{ pipelineData?.backend?.pipeline_id }}
				</a>
				<span>- {{ pipelineData?.backend?.pipeline_timestamp | date: 'dd/MM HH:mm' }}</span>
			</p>
			<p>
				P :
				<a
					[href]="
						'https://gitlab.lampiris.be/activation/be-regstar-process/-/pipelines/' + pipelineData?.process?.pipeline_id
					">
					{{ pipelineData?.process?.pipeline_id }}
				</a>
				<span>- {{ pipelineData?.process?.pipeline_timestamp | date: 'dd/MM HH:mm' }}</span>
			</p>
		</div>
	</div>
	<span class="version" *ngIf="!collapse">regstar-be-{{ appVersion }}</span>
</clr-vertical-nav>
