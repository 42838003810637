export enum SalesContextContext {
	ALL = 'ALL',
	BUYING_GROUP = 'BUYING_GROUP'
}

export interface SalesContext {
	code: string;
	name: string;
	segment: string;
	context: SalesContextContext;
	subSalesContexts: SalesContext[];
}
